<template>
  <span v-if="showMinMaxValue" class="font-bold pr-md">
    {{ currentValue[0] }}
  </span>
  <Slider
    v-model="currentValue"
    :classes="classes"
    :min="min"
    :max="max"
    :step="step"
    :tooltips="false"
    :lazy="false"
  />
  <span v-if="showMinMaxValue" class="font-bold pl-md">
    {{ currentValue[1] }}
  </span>
</template>

<script setup lang="ts">
import Slider from '@vueform/slider';

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
    default: () => [],
  },
  min: {
    type: Number,
    required: false,
    default: 0,
  },
  max: {
    type: Number,
    required: false,
    default: 100,
  },
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  showMinMaxValue: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: unknown[]): void;
}>();

const classes = {
  target: 'slider-target w-full',
  handle: 'slider-handle p-[2.14px]',
  touchArea:
    'slider-touch-area border-[3px] border-solid border-white rounded-full',
  connect: 'slider-connect !bg-primary-base',
};

const currentValue = ref(props.modelValue);
watch(
  () => currentValue.value,
  (v) => {
    emit('update:modelValue', v);
  },
);

//use computed for the watcher, e.g. range filter for semknox search
const modelValue = computed(() => props.modelValue);
watch(
  () => modelValue.value,
  (v) => {
    currentValue.value = v;
  },
);
</script>

<style scoped lang="postcss">
.slider-handle {
  padding: 2.14px;
}

.slider-touch-area {
  border: 3px solid white;
  border-radius: 9999px;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>
